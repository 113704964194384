
import * as React from "react"
import PropTypes from "prop-types"
import Footer from "../components/footer"
import OverlayForm from "../components/form"
import PageTransition from "../components/pageTransition"
import "../scss/site.scss"  

const Layout = ({ children, page }) => {
  return (
    <main className={page}>
      <OverlayForm />
      <PageTransition />
      <div className="page-will--translate">
        <div className="main--wrapper mh-80vh">
          {children}
        </div>
        <Footer />
      </div>
    </main>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
