import React, { useEffect } from "react"
import { gsap } from "gsap"; 

const PageTransition  = ({internal}) => {
  useEffect(() => { 
      document.body.style.backgroundColor = 'inherit';
      setTimeout(function(){ document.body.classList.remove('is-loading'); document.querySelector('.page-will--translate').style.transform='none';}, 1600); 
      if(window.innerWidth > 768) {
        gsap.fromTo(".page-will--translate", {y: 60, opacity:0}, {y: 0, z:0, duration: 1.1, delay:0.1, ease:"power3.inOut"}); 
      }
      gsap.to('.page-will--translate', { opacity: 1, duration:.85, ease:"power3.inOut"}); 
    
  },[internal]);
  return(
    <></> 
  )
}
export default PageTransition