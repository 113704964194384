import * as React from "react"
import Spacer from "../components/spacer"
import Link from "./link"
const Footer = () => {
  return (
    <footer className="colophon text-center">
      <Spacer />
      <div className="text--banner p40">
        <div className="max-500 ma fade--in" data-sal>
          <p className="h3 m0">Looking to work together? <br />
            For <span className="inline">
              <Link  to='/folio/?filter=editorial' className="flex link ul-link inverse l-no-p">editorial<sup>1</sup></Link></span>, <span className="inline">
              <Link to='/folio/?filter=campaign' className="flex link ul-link inverse l-no-p">campaign<sup>2</sup></Link></span>, <span className="inline">
              <Link to='/folio/?filter=portrait' className="flex link ul-link inverse l-no-p">portrait<sup>3</sup></Link></span> and <span className="inline">
              <Link  to='/folio/?filter=wedding' className="flex link ul-link inverse l-no-p">wedding<sup>4</sup></Link></span>
              <span> opportunities be sure to drop Grace a line..</span></p> 
          <div className="mt40 fade--in" data-sal data-sal-delay="200">
            <div className="flex justify-center uppercase med"> 
              <a className="link ul-link mr20 spacing m-xs" href="mailto:mailto:hello@graceelizabeth.com.au">Email</a>
              <a className="link ul-link ml20 spacing m-xs" href="https://www.instagram.com/graceelizabeth.au">Follow</a>
            </div>
            <div className="location mt40">
              Newstead Studios<br />
              Level 1, 22 Ross Street<br />
              Newstead QLD 4006
            </div>
          </div>
        </div>
      </div>
      <Spacer />
      <div className="acknowledgement p20 line--in grid grid-3 m-grid" data-sal data-sal-delay="400">
        <div className="m-hide" /> 
        <div className="max-600 x-sm ma fade--in" data-sal data-sal-delay="500">
          We acknowledge and pay respect to the Jagera people and the Turrbal people as the traditional owners of Meanjin (Brisbane), the lands on which our office is located and where we meet, work and create.
        </div>
        <div className="flex justify-end m-hide align-center  fade--in x-sm " data-sal data-sal-delay="500">
         <a className="link ul-link inverse l-small" target="_blank" rel="noreferrer" href="https://groundcrew.com.au/"> By Groundcrew</a>
        </div>
      </div>
    </footer>) 
}


export default Footer
