import React, { useState } from 'react' 
const ContactForm = () => {
  const [submitted, setSubmitted] = useState(false)

  async function submit(event) {
    try {
      event.preventDefault()
      const form = event.target
      const data = new FormData(form)
      await fetch('/', {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: new URLSearchParams(data).toString(),
      })
      setSubmitted(true)

    } catch (e) {
      console.error(e)
    }
  }

  const closeOverlay = () => {
    document.querySelector('.overlay--form').classList.remove('active');
    document.querySelector('.overlay--background').classList.remove('active');
    document.querySelector('.menu-button').classList.remove('open');
    document.querySelector('.mobile--menu').classList.remove('active');
  }

  return (
    <>
      <div className="overlay--background" role="presentation" onClick={(event) => closeOverlay()}></div>
      <div className="overlay--form text-light flex flex-wrap">
        <div className="header flex p40 align-center mba w-100">
          <div className="logo"></div>
          <p className="mla m0 uppercase med small hover-cursor spacing m-xs" role="presentation" onClick={(event) => closeOverlay()}>Close</p>
        </div>
        <div className="form mta m-m0 p40 w-100 pos-rel">
          <div className={'fade--in ' + (!submitted ? 'animate' : '')}>
            <div className="intro--text medium">
              For general enquiries, collaboration or to simply say hello, <br/>please fill out the form below and I’ll be in touch.
            </div>
            <form onSubmit={submit} className='contact--form mt80 m-mt40 flex flex-wrap' name="contact" method="POST" data-netlify="true">
              <input type="hidden" name="form-name" value="contact" />
              <input type="hidden" id="subject" name="Subject" value="Grace Elizabeth Form Submission" />
              <input className="input hover-cursor w-50 m-100 mb20" name='First Name' required placeholder='First Name' />
              <input className="input hover-cursor w-50 m-100  mb20" name='Last Name' required placeholder='Last Name' />
              <input className="input hover-cursor w-100 mb20" name='Email' required placeholder='Email' />
              <input className="input hover-cursor w-100 mb20 " placeholder='Subject' name='Introduction' /> 
              <textarea placeholder='Message' className='input hover-cursor w-100' name='message' />

              <div className="w-100 mt80 m-mb100 m-mt40">
                <button className="uppercase ul-link med spacing light hover-cursor">Submit</button>
              </div>
            </form>
          </div>
          <div className={'bg-image intro--text medium flex  justify-center align-center fade--in ' + (submitted ? 'animate pointer-all ' : 'pointer-none')}>
            Form submitted messaged. Thank you! I'll be in touch shortly.
          </div>
        </div>
      </div>
    </>
  )
}
export default ContactForm
